.forgotPass {
  font-size: 0.875rem;
  line-height: 1.3125;
  float: right;
  font-weight: bold;
  color: #fdcd26;
  cursor: pointer;
  margin: 0 0 1.25rem;
}
.orContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}
.orContainer span:nth-child(1),
.orContainer span:nth-child(3) {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #dfdfdf;
}
.orContainer span:nth-child(2) {
  margin: 0 0.5rem;
  font-size: 1rem;
  color: #a0a0a0;
  font-weight: bold;
}
.errorBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #f15252;
  font-size: 0.75rem;
  margin: 0 0 0.75rem;
}
.errorBlock svg {
  margin-right: 0.25rem;
}
.errorBlock span {
  color: #f15252;
  font-size: 0.75rem;
  margin: 0;
}
.forgotSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.forgotSuccess div {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}
.forgotSuccess div svg {
  margin-right: 0.5rem;
}
