.contentLoaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentLoader {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 0.25rem solid #33cc99;
  border-top-color: transparent;
  animation: spinner 1s ease-in-out infinite;
}

.buttonLoader {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 0.25rem solid #fff;
  border-top-color: transparent;
  animation: spinner 1s ease-in-out infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
