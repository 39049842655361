.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 2147483647;
  overflow-y: auto;
}

.modalBody {
  width: 90%;
  max-width: 520px;
  border-radius: 3px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  position: relative;
  padding: 2rem 1.875rem;
  margin: 1rem;
}
.closeButton {
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bodyContainer {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    align-items: flex-start;
    background-color: #fff;
  }
  .modalBody {
    width: 100%;
    margin: 0;
    box-shadow: none;
  }
}
@media screen and (min-width: 600px) and (max-width: 1200px) {
  .container {
    align-items: flex-start;
  }
}
