:root {
  font-size: 16px;
}
.outerContainer {
  max-width: 520px;
}
.outerContainer *,
.outerContainer *::before,
.outerContainer *::after {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  color: #484848;
}

.container {
  width: 100%;
}
.heading2 {
  font-size: 26px;
  font-weight: bold;
  line-height: calc(1em * 1.38);
  margin-bottom: calc(1em * 1.38);
  color: #484848;
}
.small {
  font-size: 0.875rem;
}
.light {
  font-weight: 300;
}
.bold {
  font-weight: bold;
}
.tiny {
  font-size: 0.75rem;
}
.rightEmailIcon {
  background-image: url('../imgs/email-icon.svg');
  background-position: 98% 50%;
  background-size: 1.125rem;
  background-repeat: no-repeat;
}

.rightLockIcon {
  background-image: url('../imgs/password-icon.svg');
  background-position: 98% 50%;
  background-size: 1rem;
  background-repeat: no-repeat;
}
.rightNameIcon {
  background-image: url('../imgs/name-icon.svg');
  background-position: 98% 50%;
  background-size: 1rem;
  background-repeat: no-repeat;
}
.rightPhoneIcon {
  background-image: url('../imgs/phone-icon.svg');
  background-position: 98% 50%;
  background-size: 0.75rem;
  background-repeat: no-repeat;
}
.displayFlex {
  display: flex !important;
}
.justifyContentCenter {
  justify-content: center !important;
}
.justifyContentBetween {
  justify-content: space-between !important;
}
.divider {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
}
.link {
  color: #fdcd26;
  cursor: pointer;
  text-decoration: none;
}
.backButton {
  height: 26px;
  border-radius: 15px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.625rem;
  cursor: pointer;
  width: 4.5rem;
  margin-bottom: 1.25rem;
}
.backButton svg {
  /* margin-right: 0.25rem; */
}
.backButton span {
  margin-left: 0.625rem;
  font-size: 0.75rem;
  font-weight: 400;
}

.noMargin {
  margin: 0 !important;
}

.googleButton {
  background-color: #fff !important;
  padding-left: 0 !important;
  border: 1px solid #dfdfdf !important;
  width: 100%;
  justify-content: flex-start !important;
  font-family: 'Roboto', sans-serif;
}
.googleButton:hover {
  background-color: #fff !important;
  border-color: #a0a0a0 !important;
}
.googleButton span:first-child {
  display: inline-block;
  width: 2.75rem;
  height: 2.5rem;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin-right: 0.5rem !important;
  color: #484848 !important;
}
.googleButton span:last-child {
  display: flex;
  justify-content: center;
  width: 100%;
}
.facebookButton {
  background-color: #1877f2 !important;
  color: #fff !important;
  border: none !important;
  padding-left: 0 !important;
  width: 100%;
  justify-content: flex-start !important;
  font-family: 'Roboto', sans-serif;
}
.facebookButton span:first-child {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 0.25rem;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  margin-right: 0.5rem !important;
  color: #fff !important;
}
.facebookButton span:last-child {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #fff !important;
}
.facebookButton:hover {
  background-color: #0c5cc4 !important;
}
.signUpWithEmail {
  justify-content: flex-start !important;
}
.signUpWithEmail span {
  width: 100%;
  color: #fff !important;
}
