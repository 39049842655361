.phoneFieldParent {
  margin-bottom: 0.95rem !important;
}

.phoneFieldParent p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  margin: 0.25rem 0 0;
  color: #484848;
  
}
.phoneFieldParent p svg {
  margin: 0 0.25rem 0 0;
}
.disclaimer p {
  font-size: 0.75rem;
  margin-bottom: 1rem;
  line-height: 1.38;
}
.resendHeader {
  align-items: center;
}
.resendHeader div {
  margin: 0;
}
.resendHeader button {
  background-color: transparent;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  color: #fdcd26;
  font-weight: bold;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
