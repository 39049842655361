.input {
  height: 2.625rem;
  width: 100%;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 3px;
  outline: none;
  font-size: 1rem !important;
  color: #484848;
  font-weight: 500;
  text-indent: 0.625rem;
  margin-bottom: 0.95rem;
}
.input:focus {
  border-color: #a0a0a0;
}
.input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 0.875rem;
  font-weight: 300;
  opacity: 1;
  color: #a0a0a0;
}
.input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.875rem;
  font-weight: 300;
  opacity: 1;
  color: #a0a0a0;
}
.input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0.875rem;
  font-weight: 300;
  opacity: 1;
  color: #a0a0a0;
}
.input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.875rem;
  font-weight: 300;
  opacity: 1;
  color: #a0a0a0;
}
.input:read-only {
  background-color: #fff;
  border: none;
  outline: none;
  font-size: 1rem;
  text-indent: 0;
  font-weight: 400;
}
.error {
  border-color: #f91c1c;
  background-color: rgba(241, 82, 82, 0.05);
}
/* .error::-webkit-input-placeholder {
  color: #f91c1c;
}
.error::-moz-placeholder {
  color: #f91c1c;
}
.error:-ms-input-placeholder {
  color: #f91c1c;
}
.error:-moz-placeholder {
  color: #f91c1c;
} */

.checkbox {
  display: inline-block;
  position: relative;
  margin-bottom: 1.25rem;
}
.checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.checkbox div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.checkbox div > span:first-child {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 3px;
  border: solid 1px #dfdfdf;
  background-color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
}
.checkbox div > span span {
  width: 0.5rem;
  height: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox div > span:last-child {
  font-size: 0.75rem;
}
.checkbox div svg {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.checkbox input[type="checkbox"]:checked ~ div svg {
  opacity: 1;
}
.button {
  height: 2.75rem;
  border-radius: 3px;
  border: solid 1px #a0a0a0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.25rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: #484848;
  outline: none;
  user-select: none;
  background-color: #fff;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 0.625rem;
  outline: none;
}
.button:hover {
  background-color: #f5f5f5;
}
.button:disabled {
  background-color: #fff;
  color: #dfdfdf;
  border-color: #dfdfdf;
  cursor: not-allowed;
}
.primary {
  background-color: #33cc99;
  color: #fff;
  border: none;
}

.primary:hover {
  background-color: #2db88a;
}
.primary:disabled {
  background-color: #aeecd7;
  cursor: not-allowed;
}

.buttonParent{
  margin-top: 1.25em;
}

.select {
  height: 2.625rem;
  width: 100%;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 3px;
  font-size: 1rem !important;
  color: #484848;
  font-weight: 500;
  background-color: #fff;
  padding: 0.2rem;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.block {
  width: 100%;
}
.noMargin {
  margin: 0 !important;
}
